import styled from 'styled-components';
import { Link } from "react-router-dom"
import { SpanPolitics } from "components/birthday-input/style.custom";

const Arrow = () => {
  return (
    <svg width="25" height="25" viewBox="0 0 25 25" fill="white" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12.5" cy="12.5" r="9.5" fill="white" />
      <path d="M12.5 2.46875C14.3392 2.46875 16.0482 2.93262 17.627 3.86035C19.1569 4.75553 20.3695 5.9681 21.2646 7.49805C22.1924 9.07682 22.6562 10.7858 22.6562 12.625C22.6562 14.4642 22.1924 16.1732 21.2646 17.752C20.3695 19.2819 19.1569 20.4945 17.627 21.3896C16.0482 22.3174 14.3392 22.7812 12.5 22.7812C10.6608 22.7812 8.95182 22.3174 7.37305 21.3896C5.8431 20.4945 4.63053 19.2819 3.73535 17.752C2.80762 16.1732 2.34375 14.4642 2.34375 12.625C2.34375 10.7858 2.80762 9.07682 3.73535 7.49805C4.63053 5.9681 5.8431 4.75553 7.37305 3.86035C8.95182 2.93262 10.6608 2.46875 12.5 2.46875ZM12.5 4.03125C10.9375 4.03125 9.48893 4.42188 8.1543 5.20312C6.86849 5.9681 5.8431 6.99349 5.07812 8.2793C4.29688 9.61393 3.90625 11.0625 3.90625 12.625C3.90625 14.1875 4.29688 15.6361 5.07812 16.9707C5.8431 18.2565 6.86849 19.2819 8.1543 20.0469C9.48893 20.8281 10.9375 21.2188 12.5 21.2188C14.0625 21.2188 15.5111 20.8281 16.8457 20.0469C18.1315 19.2819 19.1569 18.2565 19.9219 16.9707C20.7031 15.6361 21.0938 14.1875 21.0938 12.625C21.0938 11.0625 20.7031 9.61393 19.9219 8.2793C19.1569 6.99349 18.1315 5.9681 16.8457 5.20312C15.5111 4.42188 14.0625 4.03125 12.5 4.03125ZM13.0615 7.62012L18.0664 12.625L13.0615 17.6299L11.9385 16.5312L15.0635 13.4062H7.03125V11.8438H15.0635L11.9385 8.71875L13.0615 7.62012Z" fill="#B54540" />
    </svg>
  )
}

const TitleHTP = styled.p`
  color: #000;
  font-family: Balinese Culture;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 107.8%;
`

const SubtitleHTP = styled.p`
  color: rgba(0, 0, 0, 0.50);
  font-family: Frank;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
const RulesText = styled.p`
  color: #000;
  font-family: Frank;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`
const RulesTextBottom = styled.p`
  color: #000;
  font-family: Frank;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const languageES = {
  legalage: {
    title: ' ',
    button1: <span style={{ fontSize: '14px' }}>SI, SOY MAYOR</span>,
    button2: <span style={{ fontSize: '14px' }}>NO, SOY MENOR</span>,
    question: '¿Eres mayor de edad?'
  },
  agegate: {
    title: <></>,
    description: <>
      <img src={`${AZURE_BASE_URL}/agegate.svg`} alt='bienvenida agegate' className='mx-auto w-full max-w-md -mb-5' />
      <h1 className="pt-5" style={{
        color: '#000',
        textAlign: 'center',
        fontFamily: 'Balinese Culture',
        fontSize: '32px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '30px'
      }}>¿Eres mayor de edad?</h1>
    </>,
    politics1: <span className='hidden'></span>,
    politics2: <SpanPolitics>
      AL CONFIRMAR, ACEPTAS LA <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">POLÍTICA DE PRIVACIDAD</a >, <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">TÉRMINOS Y CONDICIONES Y USO DE COOKIES</a > DE ESTE SITIO WEB. ESTE SITIO WEB UTILIZA COOKIES. PARA OBTENER MÁS INFORMACIÓN, LEE NUESTRA POLÍTICA DE <a href={`${process.env.PUBLIC_URL}/privacy-politics`} rel="noreferrer" target="_blank" className="underline">PRIVACIDAD</a > Y USO DE <a href={`${process.env.PUBLIC_URL}/terms-and-conditions`} rel="noreferrer" target="_blank" className="underline">COOKIES</a >.
    </SpanPolitics>
  },
  signup: {
    title: 'Bienvenido',
    button: 'SIGUIENTE',
    welcome: {
      subtitle: "Solo nos falta validar tu cuenta",
      description: <>Te enviamos un código de 6 dígitos a tu correo electrónico para que valides.</>
    }
  },
  start: {
    signup: 'REGÍSTRATE CON CORREO',
    signin: 'INGRESA CON CORREO',
    social: 'Entra con:',
    title: {
      html:
        <div className="flex-1 w-full pb-2 flex flex-col items-center">
          <img src={`${AZURE_BASE_URL}/fondo-inicial.jpg`} alt="background" className="w-full" />
        </div>
    }
  },
  home: {
    title: <span className="font-bold" style={{
      color: '#FFF',
      fontFamily: 'Balinese Culture',
      fontSize: '33px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal'
    }}>¿Qué quieres hacer?</span>,
    description: '¡Completa tus datos!',
    popup: {
      two: {
        mobile: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-mobile.png',
        desktop: 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop.png',
        'desktop-1500': 'https://stblobphcentral.blob.core.windows.net/silver/assets/popup-2-desktop-1500.png'
      }
    }
  },
  profile: {
    form: {
      information: {
        firstname: {
          label: '¿Cómo te llamas?',
          placeholder: 'Pon tu nombre aquí'
        },
        lastname: {
          placeholder: 'Pon tu apellido aquí',
        },
        nickname: {
          label: '¿TIENES ALGÚN APODO? (opcional)',
          placeholder: 'Pon tu apodo aquí'
        },
        gender: {
          label: '¿Cómo te identificas?'
        },
        email: {
          label: 'Correo electrónico'
        }
      }
    },
    information: 'DATOS PERSONALES',
    address: 'DIRECCIÓN',
    contact: 'CONTACTO',
    button: 'IR A VER LOS PREMIOS',
    state: 'HISTORIAL DE CANJES',
    stateNotFound: <>
      Parece que aún no has conseguido ningún premio. <br></br><br></br><b>¡Venga, tú puedes!</b>
    </>,
    identification: {
      nav: 'MI IDENTIFICACIÓN',
      label: 'MI IDENTIFICACIÓN',
      title: 'HNK MESEROS ID',
      button: 'DESCARGAR ID'
    },
    view: {
      arrow: <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6.96094 0.0859375L8.03906 1.16406L2.95312 6.25H18V7.75H2.95312L8.03906 12.8359L6.96094 13.9141L0.585938 7.53906L0.0703125 7L0.585938 6.46094L6.96094 0.0859375Z" fill="black" />
      </svg>

    },
    consumptionLabel: 'Número de cliente',
    consumption: 'Número de cliente',
    consumptionEmpty: <>Aún no has ingresado tu número de cliente. <br /> ¡no te quedes sin hacerlo!</>
  },
  config: {
    prizes: '/prizes'
  },
  congrats: {
    title: 'Veeeeeenga ',
    description: 'Obtuviste los siguientes puntos:',
    range: {
      title: "NUEVO LOGRO DESBLOQUEADO",
      description: <></>,
      description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
    },
    button2Link: '/redeem'
  },
  endroute: {
    title: <>¡Lo sentimos! <br /> Esto es solo <br /> para mayores</>,
    description: 'Vuelve cuando sea más grande.'
  },
  referrals: {
    description: 'Comparte tu código con amigos y gana más oportunidades de ganar.',
    subtitle: 'COMPARTE TU CÓDIGO Y GANA',
    left: 'COPIAR',
    right: 'COMPARTIR'
  },
  trivias: {
    exchange: {
      button: {
        text: 'SUBIR OTRA IMAGEN',
        url: '/album/redirect/Foto/upload'
      }
    },
    triviaQr: {
      register: {
        title: <div className="text-white">CONTESTA<br /><span style={{ color: '#CCB988' }}>Y GANA</span></div>,
        description: <div className="text-white">
          <p>¿Ya tienes tu CRISTAL ID?</p>
          <b className="mt-3">SI NO LO TIENES CÓRRELE A REGISTRARTE PARA PODER PARTICIPAR</b>
          <img className="mx-auto mt-5" src="https://promohubstorage.blob.core.windows.net/cristal/assets/crista_id_ej.svg" />
        </div>,
        html: <>
          <div className="flex-1 w-full flex px-7 items-center justify-center text-center">
            <h1 className="source-serif-pro text-5xl font-bold text-white">Contesta<br /><span style={{ color: '#15636A' }}>y gana</span></h1>
          </div>
          <img src={`${AZURE_BASE_URL}/trivia-qr-footer.png`} />
        </>
      },
      late: {
        title: <>¡Ups!<br />Ya es tarde</>,
        description: <p className="text-white">Lamentablemente esta trivia ya no está disponible.</p>
      }
    },

    questions1: <>Contesta </>,
    questions2: <> <span className="font-bold" style={{ color: '#15636A' }}>preguntas</span> sobre el tema y gana:</>,
  },
  tivias: {
    challenge: {
      modal: {
        description: '¿Seguro que quieres empezar a jugar? Una vez que des click no hay vuelta atrás.'
      }
    },
  },
  password: {
    label: {
      newPassword: 'NUEVA CONTRASEÑA',
      newPasswordConfirm: 'REPETIR NUEVA CONTRASEÑA'
    }
  },
  survey: {
    title: 'CUÉNTANOS DE TI',
    button: 'RESPONDER'
  },
  mail: {
    description: <>Has confirmado con éxito tu cuenta. <br /> Ya puedes ingresar.</>,
    title: <>¡EXCELENTE name!</>
  },
  prizes: {
    start: {
      title: 'Premios'
    },
    congrats: {
      description: <>Revisa tu email que te vamos a mandar toda la info del envío de tu canje ¡Felicidades!</>
    },
    description: {
      politic: 'Recuerda que debes tener tus datos actualizados para poder canjear'
    },
    btn: {
      text: 'CANJEAR'
    }
  },
  album: {
    title: 'Retos',
    states: {
      progress: 'ACTUALES',
      completed: 'PASADOS'
    },
  },
  popUps: {
    start: {
      html: <div className="flex-1 flex flex-col gap-5 items-center pt-5">
        <h1 className="font-bold text-3xl source-serif-pro">¿Cómo participar?</h1>
        <p className="source-serif-pro text-xl font-normal" style={{ color: '#00A7B5' }}>ACUMULA PUNTOS Y CANJEA</p>
        <img src={`${AZURE_BASE_URL}/start-popup-header.png`} />
        <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
          <li className="flex flex-row w-full gap-5">
            <img src={`${AZURE_BASE_URL}/start-popup-1.svg`} />
            <div className="flex-1 flex flex-col justify-center">
              <p className="font-bold text-lg source-serif-pro leading-4" style={{ color: '#00A7B5' }}>Regístrate.</p>
              <p className="font-normal text-sm source-serif-pro" style={{ color: '#00A7B5' }}>Crea tu cuenta personal para empezar a juntar puntos.</p>
            </div>
          </li>
          <li className="flex flex-row w-full gap-5">
            <img src={`${AZURE_BASE_URL}/start-popup-3.svg`} />
            <div className="flex-1 flex flex-col justify-center">
              <p className="font-bold text-lg source-serif-pro leading-4" style={{ color: '#00A7B5' }}>Participa.</p>
              <p className="font-normal text-sm source-serif-pro" style={{ color: '#00A7B5' }}>Registra tu hermetapa para acumular puntos.</p>
            </div>
          </li>
          <li className="flex flex-row w-full gap-5">
            <img src={`${AZURE_BASE_URL}/start-popup-2.svg`} />
            <div className="flex-1 flex flex-col justify-center">
              <p className="font-bold text-lg source-serif-pro leading-4" style={{ color: '#00A7B5' }}>Canjea.</p>
              <p className="font-normal text-sm source-serif-pro" style={{ color: '#00A7B5' }}>Acumula tus puntos para canjearlos por grandes premios.</p>
            </div>
          </li>
        </ul>
      </div>
    }
  },
  social: {
    upload: {
      alreadyUploaded: {
        description: 'Completa la trivia y aumenta tus chances de ganar',
        button: {
          text: '¿VAMOS POR LA TRIVIA?',
          url: '/trivia/qr/1'
        }
      },
      howToTakePhoto: {
        "how-take-photo-img": "foto_modelo_imagen.webp",
        "how-take-photo-list": (
          <ul style={{ padding: 'revert', listStyle: 'disc' }}>
            <li className="text-white pb-5">
              Intenta que la foto salga nítida, con buena iluminación y sobre un fondo lo más limpio y plano posible (que no tenga otros objetos de fondo)
            </li>
            <li className="text-white pb-5">
              Procura que el ticket se vea lo mejor posible, sin arrugas o roturas.
            </li>
            <li className="text-white">
              Se tiene que poder identificar la Fecha del ticket, nombre del lugar de venta (centro de consumo) y debe verse el producto participante en la promoción (variantes de HeiNeKen o DOS EQUIS).
            </li>
          </ul>
        ),
      }
    },
    onBoarding: {
      rules: {
        list: <>
          <div className="w-full flex gap-5 justify-center">
            <Arrow />
            <div className="flex-1">
              <p className="source-serif-pro text-white font-normal text-xl">No aparecer fumando.</p>
            </div>
          </div>
          <div className="w-full flex gap-5 justify-center">
            <Arrow />
            <div className="flex-1">
              <p className="source-serif-pro text-white font-normal text-xl">No aparecer con consumo excesivo o aparentemente excesivo de bebidas alcohólicas.</p>
            </div>
          </div>
          <div className="w-full flex gap-5 justify-center">
            <Arrow />
            <div className="flex-1">
              <p className="source-serif-pro text-white font-normal text-xl">No mostrar la marca en un entorno de exceso, peligro o violencia.</p>
            </div>
          </div>
          <div className="w-full flex gap-5 justify-center">
            <Arrow />
            <div className="flex-1">
              <p className="source-serif-pro text-white font-normal text-xl">No aparecer con otras marcas de cerveza que no sea Heineken Silver.</p>
            </div>
          </div>
          <div className="w-full flex gap-5 justify-center">
            <Arrow />
            <div className="flex-1">
              <p className="source-serif-pro text-white font-normal text-xl">No aparecer con menores de edad.</p>
            </div>
          </div>
        </>
      }
    },
    profile: {
      return: {
        text: 'VER GANADORES',
        url: '/album/redirect/Foto/ranking?top=10'
      }
    },
    congrats: {
      title: 'Veeeeeenga ',
      description: 'Obtuviste los siguientes puntos:',
      range: {
        title: "NUEVO LOGRO DESBLOQUEADO",
        description: <></>,
        description2: <>Felicitaciones, has desbloqueado nuevos <br /> premios y obtienes más chances de ganar.</>
      }
    },
    Ticket: {
      howToParticipate: {
        subtitle: 'SUBE TU TICKET Y PARTICIPA',
        list: (
          <ul className="flex-1 items-center justify-center flex-col flex w-full gap-7 pb-7">
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-1.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <TitleHTP>
                  COMPLETA TU PERFIL
                </TitleHTP>
                <SubtitleHTP>
                  Completa tus datos.
                </SubtitleHTP>
              </div>
            </li>
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-2.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <TitleHTP>
                  Sube una foto del recibo
                </TitleHTP>
                <SubtitleHTP>
                  Donde se vea bien el detalle de las cervezas que vendiste.
                </SubtitleHTP>
              </div>
            </li>
            {/* <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-3.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <TitleHTP>
                  Ingresa los códigos bajo tapa 
                </TitleHTP>
                <SubtitleHTP>
                  que encuentras dentro de las diferentes presentaciones.
                </SubtitleHTP>
              </div>
            </li> */}
            <li className="flex flex-row w-full gap-5">
              <img src={`${AZURE_BASE_URL}/social-howToParticipate-4.svg`} />
              <div className="flex-1 flex flex-col justify-center">
                <TitleHTP>
                  Acumula puntos
                </TitleHTP>
                <SubtitleHTP>
                  canjéalos por premios en la vitrina.
                </SubtitleHTP>
              </div>
            </li>
          </ul>
        )
      },
      onBoarding: {
        rules: {
          list: (
            <>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <RulesText>
                    El ticket debe ser de productos Dos Equis o Heineken en alguna de sus presentaciones (Dos Equis Lager, Dos Equis Ambar, Dos Equis Ultra, Heineken Original, Heineken Silver y Heineken 0.0).
                  </RulesText>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <RulesText>
                    Se debe visualizar la fecha de compra y centro de consumo.
                  </RulesText>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <RulesText>
                    No se debe cargar un ticket repetido.
                  </RulesText>
                </div>
              </div>
              <div className="w-full flex gap-5 justify-center">
                <Arrow />
                <div className="flex-1">
                  <RulesText>
                    Los tickets validados añadirán puntos al perfil del usuario los cuales podrá usar para canjear premios en la vitrina.
                  </RulesText>
                </div>
              </div>
            </>
          )
        }
      },
      upload: {
        buttonSubmit: 'SI, AÑADIR',
        title: 'CARGAR TICKET',
        subtitle: 'CLIC PARA CARGAR TU TICKET',
        rules: {
          title: 'REGLAS PARA SUBIR TICKETS',
          list: (
            <ul style={{ color: '#000', fontSize: '13px', fontFamily: 'Industry' }}>
              <li>
                <RulesTextBottom >
                  <span>1.</span> Usa la cámara de tu celular para tomarle una foto a tu ticket.
                </RulesTextBottom>
              </li>
              <li className="my-3">
                <RulesTextBottom>
                  <span>2.</span> Busca un lugar iluminado para tomar la foto.
                </RulesTextBottom>
              </li>
              <li>
                <RulesTextBottom>
                  <span>3.</span> Mantente quieto al momento de tomar la foto para obtener una imagen de buena calidad.
                </RulesTextBottom>
              </li>
            </ul>
          )
        },
        'ranking-subtitle': {
          progress: <>Este es el ranking con las historias más votadas. <br /> Dale clic a la que más te guste para votarla.</>,
          completed: <>Este reto ya ha finalizado y no se puede votar, <br /> pero puedes leer las historias ganadoras.</>
        },
        alreadyUploaded: {
          description: 'Se ha agregado correctamente tu recibo.',
          button: {
            url: '/album/congrats',
            text: '¡VAMOS!'
          }
        },
        errorModalValidate: {
          description: <>No podemos subir tu ticket<br />correctamente debido a:</>,
          list: <div style={{ width: '320px' }}>
            <div className="industry text-black text-base font-normal">
              <p className='ml-5'> <span className="font-bold">• Llegaste al máximo de contenido que puedes cargar en 24 hs. </span></p>
            </div>
          </div>,
          help: <div style={{ width: '320px' }} className='flex justify-center items-center flex-col mb-12 industry'>
            <span className="font-bold text-black text-center text-base industry">¿Te parece que no es así?</span>
            <span className="text-center text-black text-base industry">Ponte en contacto con nosotros para que <br /> podamos ayudarte. <Link to="/help" className='underline'>Envíanos un mensaje.</Link> </span>
          </div>
        },
        icon: <svg width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="36.6821" cy="36.6821" r="36.6821" fill="white" />
          <path d="M35.4909 21.1846H38.3369V35.4147H52.567V38.2607H38.3369V52.4909H35.4909V38.2607H21.2607V35.4147H35.4909V21.1846Z" fill="#B54540" />
        </svg>,
        isReady: '¿ESTÁ OK ESTA FOTO?',
        howToTakePhoto: {
          title: "Foto modelo",
          subtitle: "CONSEJOS para tomar una foto correcta"
        }
      },
      congrats: {
        description: 'Sigue subiendo tus recibos y gana increíbles premios.',
        button2: 'QUIERO INGRESAR OTRO RECIBO',
        'button2Link': '/album/redirect/Ticket/upload',
      }
    },
    loader: {
      title: 'ESTAMOS VALIDANDO TU TICKET'
    }

  },
  redeem: {
    imageUrl: `${AZURE_BASE_URL}/redeem-welcome.png`,
    description: <span
      style={{
        color: '#000',
        fontSize: '32px',
        fontWeight: 400,
        fontFamily: "Balinese Culture",
        textTransform: 'none',
        lineHeight: '35px'
      }}>
      CARGA LOS CÓDIGOS Y
      <br />
      <span className="text-white">
        llévate GRANDES PREMIOS
      </span>
    </span>,
    description2: 'Ingresa tu código'
  },
  error: {
    66: {
      title: 'ES UNA LÁSTIMA, EL INGRESO DE CÓDIGOS HA SIDO BLOQUEADO POR 48 HRS.',
      description: 'NO TE APURES, SI ES UN MAL ENTENDIDO NOS PONDREMOS EN CONTACTO CONTIGO.'
    },
    153: {
      title: <>POR FAVOR completa tu dirección</>,
      description: <>Debemos validar tu dirección para mostrarte <br /> los premios disponibles cerca de ti.</>,
      button: 'Completar mi dirección'
    }
  }
}