import { Link } from 'react-router-dom'
import { ButtonNavStyle } from 'theme/home'

const SilverID = () => {
  return (
    <Link to='/profile/identificacion' className="w-full" onClick={() => { gtag('event', 'event_to_ga', { event_category: 'navegacion_perfil', event_label: 'Home links' }) }}>
      <ButtonNavStyle className={`button-nav flex items-center justify-evenly text-center font-medium text-xs bg-bottom bg-no-repeat bg-cover`}>
        <svg width="343" height="80" viewBox="0 0 343 80" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="343" height="80" rx="8" fill="url(#paint0_linear_3049_3642)" />
          <rect x="0.5" y="0.5" width="342" height="79" rx="7.5" stroke="#00A7B5" strokeOpacity="0.24" />
          <path d="M322.529 35.0645L327.311 39.8457L327.697 40.25L327.311 40.6543L322.529 45.4355L321.721 44.627L325.535 40.8125H314.25V39.6875H325.535L321.721 35.873L322.529 35.0645Z" fill="#15636A" />
          <g clipPath="url(#clip0_3049_3642)">
            <path d="M115.488 32.9362C115.619 32.8319 115.879 32.7275 115.879 32.4146C115.879 31.9974 115.488 31.8931 115.097 31.8931H113.793V33.875H114.315V33.1448H114.445C114.706 33.1448 114.836 33.2491 114.967 33.4577C115.097 33.562 115.097 33.7707 115.097 33.9793C115.358 33.9793 115.488 33.9793 115.749 33.9793C115.619 33.875 115.619 33.6663 115.488 33.4577C115.749 33.1448 115.749 33.0405 115.488 32.9362ZM114.967 32.6232H114.575V32.206H114.967C115.097 32.206 115.358 32.3103 115.358 32.4146C115.227 32.6232 115.097 32.6232 114.967 32.6232Z" fill="white" />
            <path d="M46.5331 43.2076C45.9202 43.8651 45.0621 44.5226 44.3265 44.7856C43.591 45.0486 41.507 45.1801 40.7715 43.3391C40.6489 42.9446 40.7715 42.8131 41.1392 42.6816C41.6296 42.5501 42.9781 42.1556 44.3265 41.4981C45.5524 40.9721 46.7783 40.3147 47.0235 40.1832C47.6364 39.7887 47.759 39.1312 47.3912 38.4737C46.5331 36.3697 44.4491 34.1343 41.1392 34.7918C38.4423 35.3178 36.6035 37.9477 36.6035 40.7091C36.6035 44.2596 38.5649 46.3635 41.1392 47.021C43.591 47.6785 46.2879 46.3635 47.5138 44.3911C48.2494 43.7336 46.9009 42.8131 46.5331 43.2076ZM40.5263 40.4461C40.2811 39.6572 40.4037 37.2902 41.7522 36.8957C42.9781 36.5012 43.8362 37.1587 44.2039 38.4737C44.3265 38.9997 44.3265 39.5257 44.0814 39.6572C43.8362 39.9202 43.4684 40.0517 43.1006 40.3146L42.2425 40.7091C41.1392 41.1036 40.7715 41.1036 40.5263 40.4461Z" fill="white" />
            <path d="M78.4656 43.2076C77.8561 43.8651 77.0028 44.5226 76.2715 44.7856C75.5401 45.0486 73.4678 45.1801 72.7365 43.3391C72.6146 42.9446 72.7365 42.8131 73.1022 42.6816C73.5897 42.5501 74.9306 42.1556 76.2715 41.4981C77.4904 40.9721 78.7094 40.3147 78.9532 40.1832C79.5627 39.7887 79.6846 39.1312 79.3189 38.4737C78.4656 36.3697 76.3934 34.1343 73.1022 34.7918C70.4204 35.3178 68.592 37.9477 68.592 40.7091C68.592 44.2596 70.5423 46.3635 73.1022 47.021C75.5401 47.6785 78.2218 46.3635 79.4408 44.3911C80.2941 43.7336 78.9532 42.8131 78.4656 43.2076ZM72.4927 40.4461C72.3708 40.0517 72.3708 38.9997 72.6146 38.0792C72.7365 37.5532 73.1022 37.1587 73.5897 36.8957C74.1992 36.6327 74.6868 36.7642 75.0525 37.0272C75.4182 37.2902 75.7839 37.8162 76.0277 38.4737C76.1496 38.9997 76.1496 39.5257 75.9058 39.6572C75.662 39.9202 75.2963 40.0517 74.9306 40.3146C74.5649 40.4461 74.1992 40.5776 74.0773 40.7091C73.224 41.1036 72.7365 41.1036 72.4927 40.4461Z" fill="white" />
            <path d="M102.165 43.2076C101.552 43.8651 100.694 44.5226 99.9587 44.7856C99.2232 45.0486 97.1392 45.1801 96.4037 43.3391C96.2811 42.9446 96.4037 42.8131 96.7714 42.6816C97.2618 42.5501 98.6103 42.1556 99.9587 41.4981C101.185 40.9721 102.41 40.3147 102.656 40.1832C103.269 39.7887 103.391 39.1312 103.023 38.4737C102.165 36.3697 100.081 34.1343 96.7714 34.7918C94.0745 35.3178 92.2357 37.9477 92.2357 40.7091C92.2357 44.2596 94.1971 46.3635 96.7714 47.021C99.2232 47.6785 101.92 46.3635 103.146 44.3911C103.882 43.7336 102.533 42.8131 102.165 43.2076ZM96.1585 40.4461C96.0359 40.0517 96.0359 38.9997 96.2811 38.0792C96.4037 37.5532 96.7714 37.1587 97.2618 36.8957C97.8747 36.6327 98.3651 36.7642 98.7328 37.0272C99.1006 37.2902 99.4684 37.8162 99.7136 38.4737C99.8361 38.9997 99.8361 39.5257 99.591 39.6572C99.3458 39.9202 98.978 40.0517 98.6103 40.3146C98.2425 40.4461 97.8747 40.5776 97.7521 40.7091C96.7714 41.1036 96.4037 41.1036 96.1585 40.4461Z" fill="white" />
            <path d="M35.3659 33.6204L36.1084 33.2502C36.2322 33.1268 36.2322 33.1268 36.2322 32.8801V32.7567L36.1084 32.1398C36.1084 31.8931 35.9847 31.8931 35.6134 31.8931H30.4155C30.0443 31.8931 29.9205 31.8931 29.9205 32.1398L29.7968 32.7567C29.7968 32.8801 29.7968 32.8801 29.7968 32.8801C29.7968 33.0035 29.7968 33.1268 29.9205 33.2502L30.5393 33.6204C30.9106 33.7437 30.9106 34.2372 30.9106 34.7308V37.4451C30.9106 37.5684 30.7868 37.8152 30.5393 37.8152H27.6929C27.5691 37.8152 27.3216 37.6918 27.3216 37.4451V34.7308C27.3216 34.3606 27.3216 33.8671 27.6929 33.6204L28.3117 33.2502C28.4354 33.1268 28.4354 33.1268 28.4354 32.8801V32.7567L28.3117 32.1398C28.3117 31.8931 28.1879 31.8931 27.8166 31.8931H22.6188C22.2475 31.8931 22.1238 31.8931 22.1238 32.1398L22 32.7567C22 32.8801 22 32.8801 22 32.8801C22 33.0035 22 33.1268 22.1238 33.2502L22.8663 33.6204C23.2376 33.7437 23.3613 34.2372 23.3613 34.7308V44.1075C23.3613 44.4776 23.2376 44.9711 22.8663 45.2179L22.1238 45.588C22 45.7114 22 45.7114 22 45.9581V46.0815L22.1238 46.9452C22.1238 47.1919 22.2475 47.1919 22.6188 47.1919H28.0641C28.4354 47.1919 28.5592 47.1919 28.5592 46.9452L28.6829 46.0815C28.6829 45.9581 28.6829 45.9581 28.6829 45.9581C28.6829 45.8348 28.6829 45.7114 28.5592 45.588L27.8166 45.2179C27.4454 45.0945 27.3216 44.601 27.3216 44.1075V40.2828C27.3216 40.1594 27.4454 39.9126 27.6929 39.9126H30.6631C30.7868 39.9126 31.0343 40.036 31.0343 40.2828V44.1075C31.0343 44.4776 30.9106 44.9711 30.5393 45.2179L29.7968 45.588C29.7968 45.7114 29.7968 45.7114 29.7968 45.9581V46.0815L29.9205 46.9452C29.9205 47.1919 30.0443 47.1919 30.4155 47.1919H35.9847C36.3559 47.1919 36.4797 47.1919 36.4797 46.9452L36.6034 46.0815C36.6034 45.9581 36.6034 45.9581 36.6034 45.9581C36.6034 45.8348 36.6034 45.7114 36.4797 45.588L35.7371 45.2179C35.3659 45.0945 35.2421 44.601 35.2421 44.1075V34.7308C34.8708 34.3606 34.9946 33.8671 35.3659 33.6204Z" fill="white" />
            <path d="M50.5115 34.6747C51.685 34.6747 52.5977 34.0256 52.5977 33.2839C52.5977 32.4494 51.685 31.8931 50.5115 31.8931C49.338 31.8931 48.4253 32.5421 48.4253 33.2839C48.4253 34.0256 49.338 34.6747 50.5115 34.6747Z" fill="white" />
            <path d="M53.8633 45.6024L53.2374 45.2356C52.8619 45.1133 52.7368 44.6242 52.7368 44.1352V36.4322C52.7368 36.31 52.6116 36.0654 52.3612 36.0654H48.6061C48.2305 36.0654 48.1054 36.0654 48.1054 36.31L47.9802 36.9213C47.9802 37.0436 47.9802 37.0436 47.9802 37.0436C47.9802 37.1658 47.9802 37.2881 48.1054 37.4104L48.4809 37.6549C48.8564 37.7772 48.9816 38.3885 48.9816 38.8776V44.2574C48.9816 44.6242 48.8564 45.1133 48.4809 45.3578L47.855 45.6024C47.7299 45.7246 47.7299 45.7246 47.7299 45.9692V46.0914L47.855 46.9473C47.855 47.1919 47.9802 47.1919 48.3557 47.1919H53.3626C53.7381 47.1919 53.7381 47.1919 53.8633 46.9473L53.9885 46.0914C53.9885 45.9692 53.9885 45.9692 53.9885 45.9692C53.9885 45.7246 53.9885 45.7246 53.8633 45.6024Z" fill="white" />
            <path d="M67.7123 44.9169C67.438 45.0366 67.0266 45.2761 67.0266 45.2761C66.8894 45.1564 66.8895 45.1564 66.8895 44.9169V39.1696C66.8895 37.2538 65.9295 36.5354 64.6952 36.1762C63.3238 35.817 61.6781 36.4157 60.8552 36.7749C60.4438 36.8946 60.1695 37.0143 60.1695 37.0143L60.0324 36.5354C59.8953 36.4157 59.8953 36.1762 59.621 36.1762H55.6439C55.2325 36.1762 55.0953 36.1762 55.0953 36.4157L54.9582 37.0143C54.9582 37.1341 54.9582 37.1341 54.9582 37.1341C54.9582 37.2538 54.9582 37.3735 55.0953 37.4933L55.5067 37.7328C55.9182 37.8525 56.0553 38.4512 56.0553 38.9301V44.1985C56.0553 44.5577 55.9182 45.0366 55.5067 45.2761L54.821 45.6353C54.6839 45.755 54.6839 45.755 54.6839 45.9945V46.1142L54.821 46.9524C54.821 47.1919 54.9582 47.1919 55.3696 47.1919H60.8552C61.2667 47.1919 61.2667 47.1919 61.4038 46.9524L61.5409 46.1142C61.5409 45.9945 61.5409 45.9945 61.5409 45.9945C61.5409 45.8748 61.5409 45.755 61.4038 45.6353L60.7181 45.2761C60.3067 45.1564 60.1695 44.6774 60.1695 44.1985V40.0077C60.1695 38.9301 60.8552 38.3314 61.5409 38.2117C62.0895 38.092 62.9124 38.4512 62.9124 39.2893V44.9169C62.9124 46.4735 64.5581 47.0721 65.6552 47.0721C67.0266 47.0721 68.1237 46.5932 68.5351 45.755C68.6723 45.3958 68.5351 45.2761 68.398 45.1564C68.1237 44.9169 67.9866 44.7972 67.7123 44.9169Z" fill="white" />
            <path d="M114.964 44.9169C114.717 45.0366 114.47 45.2761 114.347 45.2761C114.224 45.1564 114.224 45.1564 114.224 44.9169V39.1696C114.224 37.2538 113.36 36.5354 112.249 36.1762C111.015 35.817 109.533 36.4157 108.793 36.7749C108.423 36.8946 108.176 37.0143 108.176 37.0143V36.5354C108.052 36.4157 108.052 36.1762 107.805 36.1762H104.226C103.856 36.1762 103.732 36.1762 103.732 36.4157L103.609 37.0143C103.609 37.1341 103.609 37.1341 103.609 37.1341C103.609 37.2538 103.609 37.3735 103.732 37.4933L104.103 37.7328C104.473 37.8525 104.596 38.4512 104.596 38.9301V44.1985C104.596 44.5577 104.473 45.0366 104.103 45.2761L103.485 45.6353C103.362 45.755 103.362 45.755 103.362 45.9945V46.1142L103.485 46.9524C103.485 47.1919 103.609 47.1919 103.979 47.1919H108.916C109.287 47.1919 109.287 47.1919 109.41 46.9524L109.533 46.1142C109.533 45.9945 109.533 45.9945 109.533 45.9945C109.533 45.8748 109.533 45.755 109.41 45.6353L108.793 45.2761C108.423 45.1564 108.299 44.6774 108.299 44.1985V40.0077C108.299 38.9301 108.916 38.3314 109.533 38.2117C110.027 38.092 110.768 38.4512 110.768 39.2893V44.9169C110.768 46.4735 112.249 47.0721 113.236 47.0721C114.47 47.0721 115.458 46.5932 115.828 45.755C115.952 45.3958 115.828 45.2761 115.705 45.1564C115.458 44.9169 115.211 44.7972 114.964 44.9169Z" fill="white" />
            <path d="M91.9902 45.6135L91.3766 45.2492C91.0084 45.1278 90.763 44.7635 90.5176 44.3993L87.9405 39.7853L89.6585 38.2069C90.0267 37.8426 90.2721 37.5998 90.5176 37.4784C90.763 37.3569 91.1312 37.2355 91.1312 37.2355C91.2539 37.1141 91.4993 37.1141 91.4993 36.8713V36.7498L91.3766 36.0213C91.3766 35.7785 91.2539 35.7785 90.8857 35.7785H86.5906C86.3452 35.7785 86.2224 35.7785 86.0997 36.0213L85.977 36.7498C85.977 36.8713 85.977 36.8713 85.977 36.8713C85.977 36.9927 85.977 37.1141 86.0997 37.1141L86.7133 37.3569C86.836 37.4784 86.836 37.7212 86.7133 37.7212C86.5906 37.8426 86.2224 38.2069 85.977 38.3283L84.3817 39.6639V32.5002C84.3817 32.1359 84.1362 31.8931 83.7681 31.8931H80.2093C79.8411 31.8931 79.8411 31.8931 79.7184 32.1359V32.743C79.7184 32.8644 79.7184 32.8644 79.7184 32.8644C79.7184 32.9858 79.7184 33.1073 79.8411 33.2287L80.4547 33.5929C80.8228 33.7144 80.9456 34.2 80.9456 34.6857V44.1564C80.9456 44.5207 80.8228 45.0064 80.4547 45.2492L79.8411 45.6135C79.7184 45.7349 79.7184 45.7349 79.7184 45.9777V46.0991L79.8411 46.9491C79.8411 47.1919 79.9638 47.1919 80.332 47.1919H85.2407C85.6089 47.1919 85.6089 47.1919 85.7316 46.9491L85.8543 46.0991C85.8543 45.9777 85.8543 45.9777 85.8543 45.9777C85.8543 45.8563 85.8543 45.7349 85.7316 45.6135L85.118 45.2492C84.7498 45.1278 84.6271 44.6421 84.6271 44.1564V42.9422L85.4861 42.0923L87.9405 46.4634C88.1859 46.8277 88.1859 47.0705 88.6768 47.0705H91.622C91.9902 47.0705 91.9902 47.0705 92.1129 46.8277L92.2356 45.9777C92.2356 45.8563 92.2356 45.8563 92.2356 45.8563C92.2356 45.8563 92.1129 45.7349 91.9902 45.6135Z" fill="white" />
            <path d="M114.488 30.5024C113.329 30.5024 112.402 31.4296 112.402 32.5886C112.402 33.6318 113.329 34.6749 114.488 34.6749C115.647 34.6749 116.575 33.7477 116.575 32.5886C116.459 31.3137 115.647 30.5024 114.488 30.5024ZM114.488 34.0954C113.561 34.0954 112.866 33.4 112.866 32.4727C112.866 31.5455 113.561 30.8501 114.488 30.8501C115.3 30.8501 116.111 31.5455 116.111 32.4727C116.111 33.4 115.416 34.0954 114.488 34.0954Z" fill="white" />
            <path d="M126.357 34.9581C125.215 34.9581 124.324 34.1062 124.324 33.0221C124.324 31.9186 125.215 31.1054 126.357 31.1054C127.499 31.1054 128.351 31.9186 128.351 33.0221C128.351 34.1062 127.499 34.9581 126.357 34.9581ZM123.317 47L123.259 45.4706C123.375 45.4125 123.627 45.3157 123.859 45.2382L124.15 45.1414C124.653 44.9672 124.75 44.7736 124.75 44.2315V38.6752C124.75 38.0363 124.595 37.9202 124.13 37.7653L123.84 37.6685C123.608 37.591 123.375 37.5136 123.279 37.4362L123.337 35.9067H128.293V44.2315C128.293 44.7736 128.409 44.9866 128.893 45.1414L129.183 45.2382C129.416 45.3157 129.667 45.4125 129.784 45.4706L129.725 47H123.317ZM142.083 45.2382C142.315 45.3157 142.567 45.4125 142.683 45.4706L142.625 47H138.23V45.4899C137.552 46.6322 136.449 47.2323 135.132 47.2323C132.345 47.2323 130.409 44.9866 130.409 41.4437C130.409 37.9008 132.383 35.6744 135.055 35.6744C136.139 35.6744 137.03 36.1003 137.649 36.7779V34.5128C137.649 33.8739 137.494 33.7578 137.03 33.6029L136.739 33.5061C136.507 33.4286 136.294 33.3512 136.178 33.2738L136.236 31.7443H141.192V44.2315C141.192 44.7736 141.308 44.9866 141.792 45.1414L142.083 45.2382ZM135.887 45.1414C137.049 45.1414 137.649 43.9218 137.649 41.4437C137.649 38.9075 137.107 37.7653 135.965 37.7653C134.687 37.7653 134.029 38.9656 134.029 41.4243C134.029 43.9992 134.706 45.1414 135.887 45.1414Z" fill="#E6EBF1" />
          </g>
          <defs>
            <linearGradient id="paint0_linear_3049_3642" x1="0" y1="40" x2="343" y2="40" gradientUnits="userSpaceOnUse">
              <stop offset="0.162774" stopColor="#15636A" />
              <stop offset="0.588019" stopColor="#00A7B5" />
              <stop offset="1" stopColor="white" />
            </linearGradient>
            <clipPath id="clip0_3049_3642">
              <rect width="121.833" height="21" fill="white" transform="translate(22 29)" />
            </clipPath>
          </defs>
        </svg>

      </ButtonNavStyle>
    </Link>
  )
}

export default SilverID