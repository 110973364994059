import Button from 'components/button'
import ButtonCloseMenu from 'components/button-close'
import textServices from 'services/text-services'
import { useSession } from 'contexts/session-context'
import useMainClass from 'hooks/use-main-cass'
import { PrizesCouponType } from 'types/prizes.d'
import { ExchangeContainerModal, ExchangeContainerText, ExchangeContainerTop, ExchangeDescription, ExchangeImage, ExchangePointsContainer, ExchangePointsText, ExchangeTitle } from './styles'
import ScratchView from './scrachView'

interface IProps {
  item: {
    points?: number
    title: string
    imageUrl?: string
    stickerId?: number
    couponTypeId?: PrizesCouponType
  },
  buttons: {
    text?: string
    handleMenu: () => void
    dontShowButtons?: boolean
    handleButtonSticker?: () => void
    handleButtonCommon?: () => void
  }
  module?: string
  name?: string
}

function ExchangeComponent({ item, buttons, module, name }: IProps) {

  const [{ user }] = useSession()
  useMainClass(`${module}__exchange`)

  if (item?.couponTypeId === PrizesCouponType.SCRATCH) return <ScratchView item={item} buttons={buttons} module={module} />

  return (
    <ExchangeContainerModal className="flex-1 space-y-4 flex flex-col p-8 justify-between w-full sm:max-w-sm">
      <ButtonCloseMenu open={true} paramFn={buttons?.handleMenu} />
      <ExchangeContainerTop className='flex-1 flex flex-col gap-6 items-center justify-center w-full'>
        <ExchangeContainerText>
          <ExchangeTitle className="uppercase text-bold text-3xl text-center title">
            ¡{textServices.findByText(`${module}/exchange/title`, { es: 'Veeeeenga', en: 'Come on' })}<br /> {user?.firstName ?? name}!
          </ExchangeTitle>
          <ExchangeDescription className="text-center uppercase text-2xl din description">
            {textServices.findByText(`${module}/exchange/description`, { es: 'Ganaste', en: 'You won' })} <b> <br /> {item?.points <= 0 && item?.title}</b>
          </ExchangeDescription>
        </ExchangeContainerText>
        {
          item?.imageUrl
            ? (
              <ExchangeImage src={item?.imageUrl} alt={item?.title} />
            )
            : item?.points ? (
              <ExchangePointsContainer
                className="w-48 h-48 flex justify-center items-center mx-auto text-center exchange_points_crown"
                style={{ backgroundSize: 'contain' }}
              >
                <ExchangePointsText className="text-4xl font-bold mx-auto">
                  {item?.points}<br /> <span className='uppercase'>{POINTS_NAME}</span>
                </ExchangePointsText>
              </ExchangePointsContainer>
            ) : <></>
        }
      </ExchangeContainerTop>
      {
        !buttons?.dontShowButtons && (
          <Button onClick={item?.stickerId > 0 ? buttons?.handleButtonSticker : buttons?.handleButtonCommon}>
            {buttons?.text ?? textServices.findByText(`${module}/exchange/button/text`, { es: item?.stickerId > 0 ? 'VER MIS SELLOS' : 'VER MIS PREMIOS', en: item?.stickerId > 0 ? 'SEE MY SEALS' : 'SEE MY AWARDS' })}
          </Button>
        )
      }
    </ExchangeContainerModal>
  )
}

export default ExchangeComponent