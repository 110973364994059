/* eslint-disable */
import { Route, Redirect } from 'react-router-dom'
import { useSession } from 'contexts/session-context'
import useRedirect from 'hooks/use-redirect'
import useLocalStorage from 'hooks/use-local-storage'
import { useAgegate } from 'hooks/use-agegate'
import useRouter from 'hooks/use-router'
import { useEffect } from 'react'
import { ScopeEnum } from 'routes/types'
import CONFIGS from 'build/Config'

const GuardedRoute = ({ ...rest }) => {
  const [{ user }] = useSession()
  const [lState] = useLocalStorage('state', undefined)
  const { showAgegate, isMinor } = useAgegate()
  const [{ url }] = useRedirect()
  const {
    query,
    pathname
  }: {
    query: {
      referralCode?: string
      flowType?: string
      utm_campaign?: string
      utm_id?: string
      utm_content?: string
      utm_term?: string
      utm_source?: string
      utm_medium?: string
      url_redirect?: string
      internal_redirect?: string
      toexternal?: string
    }
    pathname?: string
  } = useRouter()
  const [, setRefferal] = useLocalStorage('referralCode', query?.referralCode ?? '')
  const [, setFlowType] = useLocalStorage('flowType', query?.flowType ?? '')
  const [, setExternal] = useLocalStorage('toexternal', query?.toexternal ?? '')
  const [, setUtm] = useLocalStorage(
    'utm',
    { utm_campaign: query?.utm_campaign, utm_source: query?.utm_source, utm_medium: query?.utm_medium, utm_id: query?.utm_id, utm_content: query?.utm_content, utm_term: query?.utm_term } ?? ''
  )
  const encode = encodeURI(pathname)

  useEffect(() => {
    if (query?.referralCode) setRefferal(query?.referralCode)
    if (query?.toexternal) setExternal(query?.toexternal)
    if (query?.flowType) setFlowType(query?.flowType)
    if (query?.utm_campaign || query?.utm_medium || query?.utm_source || query?.utm_term || query?.utm_content || query?.utm_id)
      setUtm({ utm_campaign: query?.utm_campaign, utm_source: query?.utm_source, utm_medium: query?.utm_medium, utm_id: query?.utm_id, utm_content: query?.utm_content, utm_term: query?.utm_term })
  }, [query])

  let isLogged = user !== undefined
  // ReCheck session in localstorage
  if (!isLogged) {
    isLogged = lState !== undefined && !!lState.user
  }

  let urlParams = ''
  let paramArray = []
  for (const key in query) {
    paramArray.push(`${key}=${query[key]}`)
  }

  if (paramArray) urlParams = `?${paramArray.join('&')}`

  if (CONFIGS?.trivia?.showIntroduction && !rest.path.includes('trivia'))
    localStorage.setItem('introductionTrivias', 'false')

  if (!showAgegate && rest.path !== '/agegate' && !rest.notAgegate) return <Redirect to={`/agegate${urlParams}`} />

  if (showAgegate && isMinor && rest.path !== '/end') return <Redirect to="/end" />

  if (isLogged && rest.scope === ScopeEnum.AUTH && rest.path !== '/home') {
    if (url && rest.scope !== ScopeEnum.AUTH) return <Redirect to={url} />
    return <Redirect to={`/home`} />
  }

  if (!isLogged && rest.scope === ScopeEnum.PRIVATE && rest.path !== '/start')
    return <Redirect to={`/start${urlParams}`} />

  return <Route {...rest} />
}

export default GuardedRoute
