import { InputProps } from "components/input";
import mergeRefs from "merge-refs";
import { forwardRef, ForwardRefRenderFunction, useRef } from "react";
import styled from "styled-components";
import { InputType } from "theme/types/generic.interface";

const Input = styled.input`
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  ::after {
    content: "";
    display: block;
    margin: auto;
    border-radius: 9999px;
    width: 13px;
    height: 13.2px;
  }
  ${({ theme }) => theme?.inputRadio?.input}
`

const P = styled.p`
  ${({ theme }) => theme?.inputRadio?.label}
`

export const InputST = forwardRef<HTMLInputElement, InputType>((props, ref) => {
  return <Input ref={ref} {...props} />
})

InputST.displayName = 'InputST'

const RadioSt: ForwardRefRenderFunction<HTMLInputElement, InputProps> = ({ children, className, ...rest }, ref) => {
  const innerRef = useRef(null)

  return (
    <label className="cursor-pointer flex items-center justify-center gap-2 radio input-radio-container">
      <InputST theme={undefined} type="radio" ref={mergeRefs(ref, innerRef) as any} className={`${className} radio__input input-radio`} {...rest} />
      <P className="flex-1 radio__label input-radio-label">{children}</P>
    </label>
  )
}

const Radio = forwardRef(RadioSt)

export default Radio;