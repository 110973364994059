import { LoaderContext } from 'contexts/loader-context'
import { useSession } from 'contexts/session-context'
import useMainClass from 'hooks/use-main-cass'
import useRouter from 'hooks/use-router'
import { useContext, useEffect, useState } from 'react'
import ProfileService from 'services/profile-services'
import { ProfileDetailsPointsContainerPoints, ProfileDetailsPointsContainerTotalPoints, ProfileDetailsPointsDatePoints, ProfileDetailsPointsDescription, ProfileDetailsPointsDescriptionPoints, ProfileDetailsPointsPoints, ProfileDetailsPointsTitlePoints, ProfileDetailsPointsTitleTotalPoints, ProfileDetailsPointsTotalPoints } from 'theme/profile'

function ProfileDetailsPoints() {

  const [{ data: session }] = useSession()
  const { setLoad } = useContext(LoaderContext)
  const { query } = useRouter()
  const [totalPoints, setTotalPoints] = useState(0)
  const [list, setList] = useState([])
  useMainClass('profile__points__details')

  const getDate = (createdAt: string) => {
    const date = new Date(createdAt)
    const day = date.getDate();
    const month = new Intl.DateTimeFormat('es-ES', { month: 'long' }).format(date);
    const year = date.getFullYear();

    const formattedDate = `${day} de ${month} ${year}`;
    return formattedDate
  }

  const fetchPoints = async () => {
    setLoad(true)
    try {
      const response = await ProfileService.getMovementsDetails({ session, categoryId: query?.categoryId })
      setTotalPoints(response?.data?.reduce((acc, item) => acc + item?.amount, 0))
      setList(response?.data)
    } catch (error) {
      console.error(error)
    } finally {
      setLoad(false)
    }
  }

  useEffect(() => {
    if (session) {
      fetchPoints()
    }
  }, [session])

  return (
    <div className="text-2xl pb-2 flex flex-col break-all">
      <ProfileDetailsPointsContainerTotalPoints className="w-full flex items-center justify-between pl-7 pr-5">
        <ProfileDetailsPointsTitleTotalPoints className='profile__points__total-text'>Total</ProfileDetailsPointsTitleTotalPoints>
        <ProfileDetailsPointsTotalPoints className="profile__points__total-points">{totalPoints}</ProfileDetailsPointsTotalPoints>
      </ProfileDetailsPointsContainerTotalPoints>
      {
        list?.map((item, index) => {
          const date = getDate(item?.createdAt)

          return (
            <ProfileDetailsPointsContainerPoints className="w-full flex flex-wrap items-center justify-between pl-7 pr-4 pt-4 profile__points__text-details" key={index}>
              <ProfileDetailsPointsDatePoints className='w-full'>{date}</ProfileDetailsPointsDatePoints>
              <ProfileDetailsPointsTitlePoints>{item?.category}</ProfileDetailsPointsTitlePoints>
              <ProfileDetailsPointsPoints className="flex items-center justify-center gap-2">{item?.amount}</ProfileDetailsPointsPoints>
              <div className='w-full flex items-center justify-between'>
                <ProfileDetailsPointsDescription><span className='profile__points__text-details__arrow'>{'> '}</span>{item?.description}</ProfileDetailsPointsDescription>
                <ProfileDetailsPointsDescriptionPoints>{item?.amount}</ProfileDetailsPointsDescriptionPoints>
              </div>
            </ProfileDetailsPointsContainerPoints>
          )
        })
      }
    </div>
  )
}

export default ProfileDetailsPoints