import { GET_PRIZES, GET_RAFFLES_WINNERS } from "constants/api";
import Button from "components/button"
import textServices from "services/text-services"
import useAxios from "axios-hooks";
import { WinnerDto } from "views/raffles/types";
import { useSession } from "contexts/session-context";
import { useContext, useEffect, useState } from "react";
import { LoaderContext } from "contexts/loader-context";
import { Prize } from "views/prizes/types";
import dayjs from "dayjs";
import { useWindowSize } from "react-use";
import { ButtonMenu } from "theme/header";
import ButtonCloseMenu from "components/button-close";
import { PopUpSpanMenu, PopUpTwo } from "views/home/style/home.style";

const One = ({ history, setPopUpFalse }) => {

  return (
    <div className="w-full pt-24 pb-7 px-7 sm:max-w-sm overflow-auto flex flex-col relative flex-1">
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Cerrar informacion del baner', value: '10' })
          setPopUpFalse()
        }}
      />
      <div className="w-full flex-1 flex items-center justify-center flex-col gap-6">
        <h1 className="text-4xl text-center font-black mb-6">{textServices.findByText('home/popup/1/title', '¡TODOS LOS DÍAS TENEMOS GANADORES!')}</h1>
        <b className="dinPro font-semibold text-lg text-center">{textServices.findByText('home/popup/1/subtitle', '¿CÓMO SUMO MÁS CHANCES DE GANAR?')}</b>
        <div className="flex w-3/4 flex-col gap-4">
          <li className="dinPro font-medium text-lg text-center">{textServices.findByText('home/popup/1/description', 'Con cada código que ingreses puedes canjearlo por una participación en nuestra vitrina.')}</li>
          <li className="dinPro font-medium text-lg text-center">{textServices.findByText('home/popup/1/description-2', 'Con cada persona que refieres y se registre')}</li>
        </div>
        <b className="dinPro font-semibold text-lg text-center">{textServices.findByText('home/popup/1/description-4', '¡QUE ESTAS ESPERANDO!')}</b>
      </div>
      <Button onClick={() => {
        gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Ir a ingresar códigos', value: '10' })
        history.push('/redeem')
      }}>
        VAMOS A INGRESAR CÓDIGOS
      </Button>
    </div>
  )
}

const Two = ({ setPopUpFalse }) => {
  return (
    <PopUpTwo className="w-full overflow-auto relative flex-1">
      <ButtonMenu
        className="h-12 w-24 py-4 px-8 absolute top-0 right-0"
        style={{ background: 'transparent' }}
        open={true}
        onClick={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Cerrar informacion del baner', value: '10' })
          setPopUpFalse()
        }}
      >
        <PopUpSpanMenu open={true} className={`transition block h-0.5 w-8 transform rotate-45 -mb-0.5`} />
        <PopUpSpanMenu open={true} className={`transition block h-0.5 w-8 transform -rotate-45 -mb-0.5`} />
      </ButtonMenu>
      <img src={textServices.findByText('home/popup/two/mobile', `${AZURE_BASE_URL}/popup-2-mobile.svg`)} className="w-full sm:hidden" />
      <img src={textServices.findByText('home/popup/two/desktop', `${AZURE_BASE_URL}/popup-2-desktop.svg`)} className="w-full hidden sm:block 2xl:hidden" />
      <img src={textServices.findByText('home/popup/two/desktop-1500', `${AZURE_BASE_URL}/popup-2-desktop-1500.svg`)} className="w-full hidden 2xl:block" />
    </PopUpTwo>
  )
}

const Three = ({ history, setPopUpFalse }) => {
  return (
    <div className="w-full pt-24 pb-7 px-7 flex-1 sm:max-w-sm relative">
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Cerrar informacion del baner', value: '10' })
          setPopUpFalse()
        }}
      />
      <div className="w-full flex-1 flex items-center justify-center flex-col gap-6">
        <h1 className="text-3xl text-center font-black mb-6">{textServices.findByText('home/popup/3/title', '¿YA VISTE LOS PREMIOS? ¿YA ESTAS PARTICIPANDO?')}</h1>
        <p className="dinPro font-normal text-base text-center">{textServices.findByText('home/popup/3/description', 'AUMENTA TUS CHANCES DE GANAR TODOS LOS DÍAS INGRESANDO CÓDIGOS E INVITANDO A TUS AMIGOS A REGISTRARSE')}</p>
        <b className="dinPro font-semibold text-lg text-center">{textServices.findByText('home/popup/3/subtitle', '¡QUE ESTAS ESPERANDO!')}</b>
      </div>
      <Button onClick={() => {
        gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Ver premios', value: '10' })
        history.push('/raffles')
      }}>
        VAMOS A VER LOS PREMIOS
      </Button>
    </div>
  )
}

const Four = ({ history, setPopUpFalse }) => {
  const [{ data: session }] = useSession()
  const [{ data = [], loading }] = useAxios<WinnerDto[]>({
    url: GET_RAFFLES_WINNERS,
    headers: { Authorization: `Bearer ${((session as any)?._tokenResponse as any)?.idToken}` }
  })
  const [{ data: prizes = [], loading: load }] = useAxios<Prize[]>({
    url: GET_PRIZES,
  })
  const { setLoad } = useContext(LoaderContext)
  const [winners, setWinners] = useState<{ name: string, date: Date, id: number, title: string }[]>()
  const { height } = useWindowSize()
  const [heightList, setHeightList] = useState<number>(500)

  useEffect(() => {
    setHeightList(height - 343)
  }, [height])


  useEffect(() => {
    if (data.length > 0 && prizes.length > 0) {
      setWinners(data.map(winner => ({
        name: winner.ganador,
        date: winner.sorteoDate,
        id: winner.sorteoId,
        title: prizes.find(r => r.id === winner.productId)?.title
      })))
    }
  }, [data, prizes])

  useEffect(() => {
    setLoad(loading ?? load)
  }, [loading, load])

  return (
    <div className="w-full space-y-4 pt-24 pb-7 px-7 flex-1 sm:max-w-sm overflow-auto relative">
      <ButtonCloseMenu
        open={true}
        paramFn={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Cerrar informacion del baner', value: '10' })
          setPopUpFalse()
        }}
      />
      <div className="w-full flex-1 flex items-center justify-center flex-col gap-4">
        <h1 className="text-3xl text-center font-black mb-6">{textServices.findByText('home/popup/4/title', 'GANADORES')}</h1>
        <div className="w-full overflow-auto space-y-4 list-winners" style={{ height: heightList }}>
          {
            winners?.map(winner => (
              <div className="w-full flex flex-col" key={winner.id}>
                <h3 className="bg-black flex w-full items-center justify-center text-center uppercase p-4">GANADOR DE {winner?.title}</h3>
                <div className="flex w-full items-center justify-center p-4 bg-white text-black gap-3">
                  <span>{dayjs(winner?.date).format('DD/MM')}</span>
                  <p className="flex-1">{winner?.name}</p>
                </div>
              </div>
            ))
          }
        </div>
      </div>
      <Button onClick={() => {
        gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Ir a ingresar códigos', value: '10' })
        history.push('/redeem')
      }}>
        QUIERO INGRESAR UN CÓDIGO
      </Button>
      <button type="button" className="cursor-pointer w-full" onClick={() => {
        gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Ver premios', value: '10' })
        history.push('/raffles')
      }}>
        <p
          className={`din text-center mt-3 hover:text-red-100 uppercase underline`}
          style={{ fontSize: 10 }}
        >
          QUIERO INSIPRARME, VER PREMIOS
        </p>
      </button>
    </div>
  )
}

const Five = ({ setPopUpFalse }) => {
  return (
    <div className="w-full overflow-auto relative flex-1">
      <ButtonMenu
        className="h-12 w-24 py-4 px-8 absolute top-0 right-0"
        style={{ background: 'transparent' }}
        open={true}
        onClick={() => {
          gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'HOME - Cerrar informacion del baner', value: '10' })
          setPopUpFalse()
        }}
      >
        <PopUpSpanMenu open={true} className={`transition block h-0.5 w-8 transform rotate-45 -mb-0.5`} />
        <PopUpSpanMenu open={true} className={`transition block h-0.5 w-8 transform -rotate-45 -mb-0.5`} />
      </ButtonMenu>
      <img src={textServices.findByText('home/popup/five/mobile', `${AZURE_BASE_URL}/popup-5-mobile.png`)} alt="popup image" className="w-full sm:hidden" />
      <img src={textServices.findByText('home/popup/five/desktop', `${AZURE_BASE_URL}/popup-5-desktop.png`)} alt="popup image" className="w-full hidden sm:block 2xl:hidden" />
      <img src={textServices.findByText('home/popup/five/desktop-1500', `${AZURE_BASE_URL}/popup-5-desktop-1500.png`)} alt="popup image" className="w-full hidden 2xl:block" />
    </div>
  )
}

export const popUps = {
  1: One,
  2: Two,
  3: Three,
  4: Four,
  5: Five
}