import { useEffect, useState } from 'react'
import { StepText, ProgressContainerSteps, ProgressSteps } from 'theme/theme'

const Steps = (props: any) => {

  const { state, htmlSteps } = props
  const [percentageStep, setPercentageStep] = useState(((state?.step * 100) / htmlSteps?.props?.children?.length) ?? 0)

  useEffect(() => {
    if (htmlSteps?.props?.children?.length > 0) {
      setPercentageStep((state?.step * 100) / htmlSteps?.props?.children?.length)
    }
  }, [htmlSteps?.props?.children])

  return (
    <div className='flex flex-col step-container'>
      <StepText className='flex mb-3 step-text'>
        <span className='text-black step-current'>{state?.step} <span className='text-black opacity-50 step-total'>/ {htmlSteps?.props?.children?.length}</span></span>
      </StepText>
      <ProgressContainerSteps className='rounded-sm step-bar'>
        <ProgressSteps className={`w-0 rounded-sm step-progress`} style={{ width: `${percentageStep}%`, transition: 'all 0.9s ease-out' }}></ProgressSteps>
      </ProgressContainerSteps>
    </div>
  )
}

export default Steps
