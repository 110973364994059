import { dlTrackEvent } from 'components/dataLayer/dataLayer'
import { useState } from 'react'
import { isInAppBrowser } from 'utilities/detectDeviceAgent';
import TwitchIcon from 'components/icon/twitch';
import AlertAppView from '../alertAppView';
import { goToUrlTwitch } from './goToUrlTwitch';

function Twitch() {

  const [alertAppView, setAlertAppView] = useState(false);

  const handleOnClick = async () => {
    dlTrackEvent('user_interaction', {
      action: "click",
      element: 'twith',
      section: "login"
    })
    gtag('event', 'Click', { event_category: GTM_EVENT_CATEGORY, event_label: 'Click - Twitch', value: 'Twitch' })

    const inBrowserApp = isInAppBrowser()
    if (inBrowserApp) return setAlertAppView(true);

    goToUrlTwitch();
  }

  return (
    <>
      <AlertAppView open={alertAppView} setOpen={setAlertAppView} social='Twitch' />
      <div onClick={handleOnClick} className="cursor-pointer">
        <TwitchIcon />
      </div>
    </>
  )
}

export default Twitch